import { TableBody, TableCell, TableRow } from '@fluentui/react-components';
import 'highlight.js/styles/github.css';
import CodeRenderer from './CodeRenderer';
import { TableRenderer, TheadRenderer, ThRenderer } from './TableRenderer';

const LinkRenderer = (props: any) => {
    // Add target and rel attributes
    return <a target="_blank" rel="noopener noreferrer" {...props} />;
};

const renderers = {
    table: TableRenderer,
    th: ThRenderer,
    thead: TheadRenderer,
    tr({ _, ...props }: any) {
        return <TableRow {...props} />;
    },
    td({ _, ...props }: any) {
        return <TableCell {...props} />;
    },
    tbody({ _, ...props }: any) {
        return <TableBody {...props} />;
    },
    a: LinkRenderer,
    code: CodeRenderer,
};

export default renderers;

import hljs from 'highlight.js'; // Import the highlight.js library
import 'highlight.js/styles/github-dark.css';
import React, { useEffect, useRef } from 'react';
import CopyCard from './CopyCard';

// Define the props interface
interface CodeHighlighterProps {
    language: string;
    code: string;
}

const CodeHighlighter: React.FC<CodeHighlighterProps> = ({ language, code }) => {
    const codeRef = useRef<HTMLElement | null>(null); // Type the ref as an HTMLElement

    useEffect(() => {
        if (codeRef.current) {
            hljs.highlightElement(codeRef.current); // Apply highlight.js to the code block
        }
    }, [code]); // Re-run the highlight whenever the code changes

    return (
        <code ref={codeRef} className={language}>
            {code}
        </code>
    );
};

export interface CodeRendererProps {
    className?: string; // className is optional
    children?: React.ReactNode;
}

const CodeRenderer = ({ className, children, ...props }: CodeRendererProps) => {
    // Ensure className is a string and match can be null, so we handle it safely
    const match = /language-(\w+)/.exec(className ?? '');

    return match ? (
        <CopyCard copyContent={String(children)} copyLabel="Copy code" cardLabel={match[1]}>
            <CodeHighlighter language={match[1]} code={String(children).replace(/\n$/, '')} />
        </CopyCard>
    ) : (
        <code className={className ?? ''} {...props}>
            {children}
        </code>
    );
};

export default CodeRenderer;

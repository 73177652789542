import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Link,
    Text,
    makeStyles,
    mergeClasses,
    shorthands,
} from '@fluentui/react-components';
import { customTokens } from '../../../styles';
import { FileIcon } from './FileIcon';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginTop: customTokens.spacingVerticalS,
        backgroundColor: customTokens.colorNeutralBackground3,
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
        width: '100%',
    },
    header: {
        '> button': {
            paddingLeft: '2px',
        },
    },
    headerText: {
        flexDirection: 'row',
        display: 'flex',
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: '100%',
    },
    downloadSpinner: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '0%',
    },
    panel: {
        paddingTop: customTokens.spacingVerticalXXS,
        paddingBottom: customTokens.spacingVerticalM,
    },
    linksContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: customTokens.spacingVerticalS,
        marginBottom: customTokens.spacingVerticalS,
        flexWrap: 'wrap',
    },
    pillWrapper: {
        marginBottom: customTokens.spacingHorizontalS,
    },
    pill: {
        ...shorthands.borderRadius(0, customTokens.borderRadiusCircular, customTokens.borderRadiusCircular, 0),
        backgroundColor: customTokens.colorBrandBackground,
        lineHeight: customTokens.lineHeightBase100,
        ...shorthands.padding(
            customTokens.spacingVerticalXXS,
            customTokens.spacingHorizontalS,
            customTokens.spacingVerticalXXS,
            customTokens.spacingHorizontalXS,
        ),
        marginRight: customTokens.spacingHorizontalS,
        fontSize: customTokens.fontSizeBase200,
    },
    pillForDocumentUpload: {
        backgroundColor: `color-mix(in srgb, ${customTokens.colorMeBackground} 70%, transparent)`,
    },
    pillWithoutCitation: {
        ...shorthands.borderRadius(customTokens.borderRadiusCircular),
    },
    citationNumberInPill: {
        ...shorthands.padding(customTokens.spacingVerticalXXS, customTokens.spacingHorizontalS),
        color: customTokens.colorBrandBackgroundInverted,
        backgroundColor: customTokens.colorBrandBackgroundPressed,
        fontSize: customTokens.fontSizeBase200,
        cursor: 'default',
        ...shorthands.borderRadius(customTokens.borderRadiusCircular, 0, 0, customTokens.borderRadiusCircular),
    },
    citationNumberInPillForDocumentUpload: {
        backgroundColor: customTokens.colorMeBackground,
    },
    link: {
        color: customTokens.colorBrandBackgroundInverted,
        ':hover': {
            color: customTokens.colorBrandBackgroundInverted,
        },
    },
    noUrl: {
        cursor: 'default',
        textDecorationLine: 'none',
        ':hover': {
            textDecorationLine: 'none',
        },
        ':active': {
            textDecorationLine: 'none',
            color: customTokens.colorBrandBackgroundInverted,
        },
    },
});

interface ICitationsProps {
    citations?: Array<{ ref: string; index: number; title: string }>;
    contextCitationsIndexes?: number[];
}

export const Citations: React.FC<ICitationsProps> = ({ citations, contextCitationsIndexes }) => {
    const classes = useClasses();

    // if there are no citations, don't render anything
    if (!citations || citations.length === 0) {
        return null;
    }

    // citations is an array of strings which are links to the sources of the information
    // we need to get the domain name of each link and display it in a list
    // for example, if the citation is https://www.cdc.gov/coronavirus/2019-ncov/vaccines/fully-vaccinated.html
    // we want to display "cdc.gov" in the list with link as the href

    const citationList: JSX.Element[] = [];
    citations.forEach(
        (citation, index) => {
            let url;
            let isValidUrl = true;

            try {
                url = new URL(citation.ref);
            } catch (e) {
                isValidUrl = false;
            }

            if (isValidUrl && url) {
                const domain = url.hostname.replace('www.', '');

                if (domain.includes('sharepoint.com') || domain.includes('blob.core.windows.net')) {
                    let documentName = url.pathname.split('/').pop();
                    if (url.searchParams.has('file')) {
                        documentName = url.searchParams.get('file') as string;
                    }

                    if (!documentName) {
                        return;
                    }

                    const decodedDocumentName = decodeURIComponent(documentName);
                    const documentNameSliced = decodedDocumentName.slice(0, 23);

                    citationList.push(
                        <>
                            <span className={mergeClasses(classes.citationNumberInPill)}>{citation.index + 1}</span>
                            <span className={classes.pill}>
                                <Link
                                    className={classes.link}
                                    key={index}
                                    title={decodedDocumentName}
                                    target={'_blank'}
                                    href={citation.ref}
                                    onClick={undefined}
                                >
                                    <FileIcon fileNameOrURL={citation.ref} />
                                    {contextCitationsIndexes?.includes(index)
                                        ? `${index + 1}. ${documentNameSliced}`
                                        : documentNameSliced}
                                    {documentNameSliced !== decodedDocumentName && '...'}
                                </Link>
                            </span>
                        </>,
                    );
                } else {
                    citationList.push(
                        <>
                            <span className={mergeClasses(classes.citationNumberInPill)}>{citation.index + 1}</span>
                            <span className={mergeClasses(classes.pill)}>
                                <Link className={classes.link} href={citation.ref} target="_blank" key={index}>
                                    {domain}
                                </Link>
                            </span>
                        </>,
                    );
                }
            } else {
                const fileName = citation.title;
                const fileNameSliced = fileName.slice(0, 23);

                citationList.push(
                    <span className={mergeClasses(classes.pill, classes.pillWithoutCitation)}>
                        <Link className={mergeClasses(classes.link, classes.noUrl)} key={index} title={fileName}>
                            <FileIcon fileNameOrURL={fileName} />
                            {contextCitationsIndexes?.includes(index)
                                ? `${index + 1}. ${fileNameSliced}`
                                : fileNameSliced}
                            {fileNameSliced !== fileName && '...'}
                        </Link>
                    </span>,
                );
            }
        },
        // }
    );

    return (
        <div className={classes.root}>
            <Accordion collapsible>
                <AccordionItem value="">
                    <AccordionHeader className={classes.header}>
                        <Text size={200} weight="bold" className={classes.headerText}>
                            {'Response citations*'}
                        </Text>
                    </AccordionHeader>
                    <AccordionPanel className={classes.panel}>
                        <Text size={200}>Here is a list of citations used to generate the response above:</Text>
                        <div className={classes.linksContainer}>
                            {citationList.map((citation, index) => (
                                <div key={index} className={classes.pillWrapper}>
                                    {citation}
                                </div>
                            ))}
                        </div>
                        <Text size={200} italic>
                            * This response was generated using a combination of internet sources and Azure ChatGPT.
                        </Text>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

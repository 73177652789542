import type { ToolbarProps } from '@fluentui/react-components';
import {
    InteractionTag,
    InteractionTagPrimary,
    InteractionTagSecondary,
    Toolbar,
    makeStyles,
} from '@fluentui/react-components';
import { FolderFilled, DocumentBulletListRegular,AddCircleRegular } from '@fluentui/react-icons';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { BucketItem, BucketType } from '../../../redux/features/buckets/BucketsState';
import { updateBucket } from '../../../redux/features/buckets/bucketsSlice';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
});

export interface IDocumentsToolbarProps extends Partial<ToolbarProps> {
    //files: File[];
    moreFilesSelected: () => void;
}

export const BucketsToolbar: FC<IDocumentsToolbarProps> = ({ moreFilesSelected, ...props }) => {
    const classes = useClasses();
    const { Buckets } = useAppSelector((state: RootState) => state.buckets);
    const [selectedBuckets, setSelectedBuckets] = useState<BucketItem[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        // Filter the array to only include selected items
        const selectedItems = Buckets.filter((item) => item.selected);
        // Create a set of parent ids for selected items
        const parentIds = new Set<string>();
        selectedItems.forEach((item) => {
            const parent = Buckets.find((b) => b.id === item.parent?.id);
            if (parent && parent.selected) {
                parentIds.add(parent.id);
            }
        });

        // Filter out items that are children of selected parents
        const result = selectedItems.filter((item) => !parentIds.has(item.parent?.id ?? ''));

        setSelectedBuckets(result);
    }, [Buckets]);

    const onRemove = (bucketSource: BucketItem) => {
        const bucketExist = Buckets.find((f) => f.id === bucketSource.id);
        if (bucketExist) {
            dispatch(updateBucket({ ...bucketExist, selected: false }));
        }
    };

    return (
        <Toolbar aria-label="Default" style={{ padding: '0 0 5px 0' }} {...props} className={classes.root}>
            {selectedBuckets.length > 0 &&
                selectedBuckets.map((f) => (
                    <InteractionTag
                        appearance="outline"
                        key={f.id}
                        style={{
                            marginRight: '8px',
                            border: `${f.color ?? f.parent?.color ? `1px solid ${f.color ?? f.parent?.color}` : ''}`,
                        }}
                    >
                        <InteractionTagPrimary
                            icon={
                                f.type === BucketType.Bucket ? (
                                    <FolderFilled fontSize={25} color={f.color} />
                                ) : (
                                    <DocumentBulletListRegular fontSize={25} />
                                )
                            }
                            hasSecondaryAction
                        >
                            {f.title.substring(0, 13)}
                        </InteractionTagPrimary>
                        <InteractionTagSecondary
                            aria-label="remove"
                            onClick={() => {
                                onRemove(f);
                            }}
                        />
                    </InteractionTag>
                ))}
            <InteractionTag appearance="outline" onClick={moreFilesSelected} style={{ border: '1px dashed #707070' }}>
                <InteractionTagPrimary icon={<AddCircleRegular />} style={{ border: '0' }}>
                    {selectedBuckets.length > 0 ? 'more' : 'Add files or buckets for better results'}
                </InteractionTagPrimary>
            </InteractionTag>
        </Toolbar>
    );
};

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { DropdownProps } from '@fluentui/react-components';
import { Dropdown, makeStyles, Option, shorthands, useId } from '@fluentui/react-components';
import * as React from 'react';
import { useChat } from '../../../libs/hooks';
import { IAppConfigResult, useAppConfig } from '../../../libs/hooks/useAppConfig';
import { AlertType } from '../../../libs/models/AlertType';
import { ISiteInfo } from '../../../libs/models/SelectedSites';
import { IAppDropdownConfigValue } from '../../../libs/services/AppConfigService';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { addAlert } from '../../../redux/features/app/appSlice';
import { ChatState } from '../../../redux/features/conversations/ChatState';
import { editConversationScope } from '../../../redux/features/conversations/conversationsSlice';
import { ScopeInfoDialog } from '../scope-dialog/ScopeInfoDialog';

interface IEditChatScopeProps extends Partial<DropdownProps> {
    chatId: string;
    chatScope: string | undefined;
}

const useStyles = makeStyles({
    root: {
        display: 'grid',
        justifyItems: 'start',
        ...shorthands.gap('20px'),
        maxWidth: '400px',
    },
    field: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'start',
        ...shorthands.gap('2px'),
    },
    optionLabel: {
        marginRight: '10px',
    },
});

export const EditChatScope: React.FC<IEditChatScopeProps> = (props) => {
    const [scopeOptions, setScopeOptions] = React.useState<IAppDropdownConfigValue[]>();
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const chat = useChat();
    const comboId = useId('combo-controlled');
    const styles = useStyles();

    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const emailAddress = activeUserInfo?.email ?? '';
    const user = emailAddress;

    const appConfig = useAppConfig();

    const getPromptGuidanceContent = async () => {
        // Fetch dropdown values for default scopes.
        const responsedropdown: IAppConfigResult<IAppDropdownConfigValue[]> =
            await appConfig.getConfigDropdownValues(user);

        if (responsedropdown.Success && responsedropdown.Data) {
            setScopeOptions(responsedropdown.Data);
        }
    };

    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getPromptGuidanceContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onOptionSelect: (typeof props)['onOptionSelect'] = (_ev, data) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        if (data.optionValue) {
            onScopeChange(data.optionValue).catch((e: any) => {
                const errorMessage = `Unable to change chat scope. Details: ${
                    e instanceof Error ? e.message : String(e)
                }`;
                dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            });
        }
    };

    const onScopeChange = async (newScope: string) => {
        const chatState: ChatState = conversations[selectedId];
        const selectedSites = chatState.selectedSites ?? ([] as ISiteInfo[]);
        await chat
            .editChat(
                chatState.id,
                chatState.title,
                chatState.memoryBalance,
                newScope,
                selectedSites,
            )
            .then(() => {
                localStorage.setItem('lastSelectedChatScope', newScope.toString());
                dispatch(editConversationScope({ id: props.chatId, newScope }));
            });
    };

    const scopeTitleByName = (name: string | undefined) => {
        if (!name || !scopeOptions) {
            return 'eya';
        }
        const scopeOption = scopeOptions.find((scope) => scope.name === name);
        return scopeOption?.title ?? 'eya';
    };

    return scopeOptions ? (
        <div className={styles.field}>
            <ScopeInfoDialog scopeOptions={scopeOptions} />
            <label htmlFor={`${comboId}-controlled`} className={styles.optionLabel}>
                Choose a source:
            </label>
            <Dropdown
                id={`${comboId}-controlled`}
                value={scopeTitleByName(props.chatScope)}
                selectedOptions={[props.chatScope ?? 'eya']}
                onOptionSelect={onOptionSelect}
            >
                {scopeOptions.map((option) => {
                    return (
                        <Option key={option.name} value={option.name}>
                            {option.title}
                        </Option>
                    );
                })}
            </Dropdown>
        </div>
    ) : null;
};
